
<script setup>
import { computed } from 'vue';
// import jsonData from '../assets/data/articles.json' 
const filteredPosts = computed(() => {
    if (!jsonData) return [];
    return []; // jsonData.filter(item => item.category === 'العمارة');
});
import cardVue from '@/components/cardVue.vue'
import breadcrumb from '@/components/breadcrumb.vue'
</script>
<template>
  <div class="Media">
   <breadcrumb/>
   <div class="parenSeries">
    <div class="container">
      <div  class="row">
    <cardVue v-for="article in filteredPosts" :key="article.id"
      :article="article" 
      class="col-sm-6 col-lg-4"/>
      </div>
      </div>
      </div>
  </div>
</template>

