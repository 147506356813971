
<script setup>
import { defineProps, ref } from 'vue';
import { useRouter } from 'vue-router';

const { serie } = defineProps(['serie']);
const currentVideosIndex = ref(0);
const routerx = useRouter();

const navToVideo = () => {
  routerx.push(`/media/${serie.videos[currentVideosIndex.value].videosId}`);
};
</script>

<template>
  
  <div class="childSeries" 
  style="background-color: var(--section-background)">
   
    <div class="container ">
    
<!-- {{  serie}} -->
          <div class="headerSeries row " >
              <div class="imagecontainer col-lg-6 ">
                <div class="post-img">
                      <img src="https://i.ytimg.com/vi/-8KUYofY8dg/maxresdefault.jpg" alt="" />
                    </div>
              </div>
              <div class="description col-lg-6">
                <h5>{{ serie.title }}
</h5>
                <div class="line"></div>
                <p>
                  {{ serie.description }}
</p>
<div class="line"></div>
<div class="row info">
  <div class="col-6">
     <p><span>تقديم :</span> حازم نصر</p>
    </div>
    <div class="col-6">
     <p><span>لغة العمل :</span> العربية</p>
    </div>
    <div class="col-6">
     <p><span>القناة :</span>  الموسوعة اليافعية</p>
    </div>

</div>
              </div>
          </div>
          <h5>حلقات سلسة قبيلة القطيبي </h5>
        <div class="line"></div>
      <div class="row " >
       
       
        <div class="col-sm-6 col-lg-4" v-for="video in serie.videos" :key="video.videosId">
          <div class="item" @click="navToVideo">
            <div class="post-slide">
              <div class="post-img">
                <img src="https://i.ytimg.com/vi/-8KUYofY8dg/maxresdefault.jpg" alt="صورة الحلقة" />
              </div>
              <div class="post-content">
                <h3 class="post-title ">
                  <a class="" href="#">{{ video.title }}</a>
                </h3>
                <h3 class="post-title ">
                  <a class="" href="#"> الحلقة : {{ video.videosId }}</a>
                </h3>
                
               
              
              </div>
            </div>
          </div>
        </div>
      
    
     
      </div>
    </div>
  </div>
</template>

<style scoped>
.headerSeries{
  position: relative;
  overflow: hidden;
 
}
 h5{
  text-align: right;
  margin: 30px 0 15px;
  color: var(--main-color);
  font-weight: 700;
  width: 100%;
       

}
.line::after {
        content: "";
        position: absolute;
        width: 10%;
        height: 3px;
        margin: 0;
        display: block;
        background-color: var(--scond-color);
    }
.headerSeries img{
  width: 100%;
  margin: 30px 10px;
  border-bottom: 4px solid var(--main-color);
}
.headerSeries p{
  margin: 30px 0 20px;
  color: var(--main-color);
  font-weight: 500;

}
.info p{
  margin: 10px 0 0;
  color: var(--main-color);
  font-weight: 500;

}
.info span{
  margin: 10px 0 0 0;
  color: var(--main-color);
  font-weight: 600;

}
.post-slide {
  background: var(--section-background);
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #8d8c8cb5;
}

.post-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;

}

.post-slide .post-img img {
  width: 100%;
  height: auto;
  transform: scale(1, 1);
  transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
  transform: scale(1.1, 1.1);
}

.post-slide .post-content {
  background: var(--section-background);
  padding: 1px 20px 15px;
  border-radius: 15px;
}

.post-slide .post-title a {
  font-size: 16px;
  font-weight: bold;
  color: var(--scond-color);
  /* display: inline-block; */
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  text-align: center;
  margin: 0;
}

.post-slide .post-title a:hover {
  text-decoration: none;
  color: var(--scond-color);
}

.post-slide .post-description {
  line-height: 24px;
  margin: 0;
  color: var(--main-color);
  margin-bottom: 15px;
}
</style>
