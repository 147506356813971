<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()
const { album } = defineProps(['album'])
const navto = () => {
  router.push(`/media/${album.id}`)
}
</script>

<template> 
          <div class="item" @click="navto">
            <div class="post-slide">
              <div class="post-img">
                <img :src="album.imgUrl" :alt="album.title" />
              </div>
              <div class="post-content">
                <h3 class="post-title ">
                  <a class="" href="#">
                    {{ album.title }}
                  </a>
                </h3>
                <h3 class="post-title ">
                  <a class="" href="#">عدد العناصر :
                     {{ album.contentEntities ? album.contentEntities.length : 0 }}
                    
                    </a>
                </h3>
                <p class="post-description" dir="">
                 {{ album.description }}
                </p>              
              
              </div>
            </div>
          </div>
   
</template>


<style scoped>
.post-slide {
  background: var(--section-background);
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #8d8c8cb5;
}

.post-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.post-slide .post-img img {
  width: 100%;
  height: auto;
  transform: scale(1, 1);
  transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
  transform: scale(1.1, 1.1);
}

.post-slide .post-content {
  background: var(--section-background);
  padding: 1px 20px 15px;
  border-radius: 15px;
}

.post-slide .post-title a {
  font-size: 16px;
  font-weight: bold;
  color: var(--scond-color);
  /* display: inline-block; */
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  text-align: center;
  margin: 0;
}

.post-slide .post-title a:hover {
  text-decoration: none;
  color: var(--scond-color);
}

.post-slide .post-description {
  line-height: 24px;
  margin: 0;
  color: var(--main-color);
  margin-bottom: 15px;
}
</style>
