<template>
  <div class="goUpVue">
    <a>
      <button
        @click="scrollToTop"
        class="goup"
        aria-hidden="true"
        style="border: 1px solid var(--scond-color)"
      >
        <i class="fa fa-arrow-up col-form-label-sm"></i>
      </button>
    </a>
  </div>
</template>

<script>
export default {
  name: "goUpVue",
  methods: {
    scrollToTop() {
      let currentScroll = document.documentElement.scrollTop,
        interval = setInterval(frame, 0);

      function frame() {
        if (0 > currentScroll) clearInterval(interval);
        else {
          currentScroll = currentScroll - 12;
          document.documentElement.scrollTop = currentScroll;
        }
      }
    },
  },
};
</script>

<style scoped>
.goup {
  position: fixed;
  bottom: 10px;
  z-index: 999;
  right: 15px;
  border-radius: 50%;
  display: block;
  background-color: var(--main-color);
  color: var(--scond-color);
  width: 50px;
  height: 50px;
  opacity: 0.5;
  color: var(--scond-color);
  font-size: 14px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.751);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.575);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.goup:hover {
  background-color: var(--scond-color);
  color: var(--main-color);
  transform: translateY(-5px);
  opacity: 1;
}
</style>

