
<script setup>
import childSeries from '@/components/childSeries.vue'
import { useRoute } from 'vue-router';
import {ref} from 'vue'
import series  from '@/assets/data/series.json'

const route =useRoute()
const serieId = parseInt(route.params.parentId);
const serie =series.find (s=>s.parentId ===serieId)

const currentSeriesIndex =ref(0)
</script>
<template>
  {{ serie }}
  <div class="series">
   <div class="">
    <div class="container-fluid">
      <div class="">
    <childSeries :vedio="serie.videos[currentSeriesIndex]"
    :serie="serie" 
    />
      </div>
      </div>
      </div>
  </div>
</template>
<style>

.container-fluid{
  padding: 0!important
}
</style>
