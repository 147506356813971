<template>

<div class="content-wrapper ">
            <div class="container">
            <div class="row mt-4 mb-4" >
            <div class="mb-2 col-lg-6 image-hover " v-for="ad in ads">
            <a target="_blank" :href="ad.adUrl">
            <img :src="ad.imgUrl" class="rounded w-100 img-fluid h-100"></a>
            </div>
            </div>
            </div>
            </div>
</template>

<script setup>
import { onMounted,ref } from 'vue';
import { getDatabase, ref as dbRef, onValue} from 'firebase/database';


const ads = ref([]); // Initialize with empty array for ads
const adsRef = dbRef(getDatabase(), "home/ads/");

onMounted(() => {
  onValue(adsRef, (snapshot) => {
    ads.value = [];
    snapshot.forEach((childSnapshot) => {
      ads.value.push({ id: childSnapshot.key, ...childSnapshot.val() });
    });
  });
});

</script>