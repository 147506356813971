<template>
  <div class="Media">
    <breadcrumb :imgUrl="img"/>
    <Bubbles :categories="childCategories"/>
    <div class="parenSeries">
      <div class="container">
        <div class="row">
          <cardVue v-for="article in articles" :key="article.id" :article="article"
           class="col-sm-6 col-lg-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Bubbles from '../components/Bubbles.vue'
import { useRoute } from 'vue-router';
import cardVue from '@/components/cardVue.vue'
import breadcrumb from '@/components/breadcrumb.vue'
import {ApiClient, CategoryControllerApi,MediaControllerApi,ArticleControllerApi,CategoryDTO} from "@/api/index.js"
import {ref,onMounted} from 'vue';

const route = useRoute();

const apiClient = new ApiClient();
const categoryControllerApi = new CategoryControllerApi(apiClient);
const articleControllerApi = new ArticleControllerApi(apiClient);
const mediaApi = new MediaControllerApi(apiClient);
const isParentCategory=ref(false);
const childCategories = ref([]);
const articles=ref([]);
const img=ref('https://i.imgur.com/cykExNN.png');

const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);

function fetchCategoryInfo(){
isParentCategory.value=!route.params.id.includes('-');
if(isParentCategory.value==true){
  categoryControllerApi.getChildCategories(route.params.id,(error,data,response)=>{
    if(!error){
      childCategories.value=data;
    }else{
      console.log(error);
    }
  });
  articleControllerApi.getTop5ArticlesByCategory(route.params.id,1,false,(error,data,response)=>{
    if(!error){
      articles.value=data;
    }else{
      console.log(error);
    }
  });
}else{
  categoryControllerApi.getCategoryById(route.params.id,(error,data,response)=>{
    if(error)
    console.log(error);
  else{  
    parseCategory(data);
  }
  });

  articleControllerApi.getArticlesByCategory(route.params.id,1,{page:currentPage.value,size:pageSize.value},(error,data,response)=>{
    if(error)
    console.log(error);
  else{
    parseArticles(data);
  }
  });
}
}

function parseCategory(data){
  let cat=CategoryDTO.constructFromObject(data);
  img.value=cat.imgCover;
}

function parseArticles(data){
        articles.value = data.content;
        currentPage.value = data.pageable.pageNumber;
        pageSize.value = data.pageable.pageSize;
        totalElements.value = data.totalElements;
}

onMounted(()=>{
  fetchCategoryInfo();
});



</script>
