
<template>
     <div class="ql-editor"
     style="color: #d06106;" v-html="props.content"/>
</template>
<script setup>

const props=defineProps( { content:{
    required:true,
    default:''
  }});

</script>
