<template>
    <div class="all-btn mb-2 mt-5">

      <a v-for="category in props.categories"
      :key="category.id"
      :href="`/category/${category.id}`"
      class="big-btn mid-btn mb-3 mr-2 ml-2">
       {{category.nameAr}}
      </a>
          </div>
</template>
<script setup>
const props=defineProps(['categories']);

</script>
<style scoped>
.mid-btn{
  padding: 10px 10px;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}
</style>