<template>
  <br>
  <br>
  <br>
  <br>
  <div class="d-flex justify-content-between">
    <br>
    <a href="https://x.com/yafe3pedia"    
    target="_blank" class="btn mx-3 mx-md-5 mx-lg-9">
      <i class="fab fa-x-twitter"></i>
    </a>
    <a href="https://www.facebook.com/yafeapedia/" target="_blank" class="btn mx-3 mx-md-5 mx-lg-9">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a href="https://www.youtube.com/channel/UCl5Zkl4hEr8Rt33uQ16HPAw" target="_blank" class="btn mx-3 mx-md-5 mx-lg-9">
      <i class="fab fa-youtube"></i>
    </a>
    <a href="https://www.instagram.com/yafeapedia/"
    style="margin-left: 20px;"
    target="_blank" class="btn mx-3 mx-md-5 mx-lg-9">
      <i class="fab fa-instagram"></i>
    </a>
    <br>

  </div>
  <br>
  <br>
  <br>
</template>

<script setup>
</script>

<style scoped>
.btn {
  font-size: 6rem; /* Medium size */
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F15827;
}
</style>