import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MediaView from '../views/MediaView.vue'
import seriesView from '../views/seriesView.vue'
import videoView from '../views/videoView.vue'
import categoriesView from '../views/categoriesView.vue'
import architectureView from '../views/architectureView.vue'
import articleView from '../views/articleView.vue'
import channelView from '../views/channelView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/category/:id',
    name: 'category',
    component: categoriesView
  }, 
  {
    path: '/architecture',
    name: 'architecture',
    component: architectureView
  }, 
  {
    path: '/article/:id',
    name: 'article',
    component: articleView
  },
  {
    path: '/media/:id',
    name: 'Media',
    component: MediaView
  },
  {
    path: '/videos',
    name: 'videos',
    component : channelView
  },
  {
    path: '/serie/:parentId',
    name: 'series',
    component: seriesView,
    videos: [
      {
        path: 'video/:videosId',
        name: 'video',
        component:videoView
        
      },
    ]
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
