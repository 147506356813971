<template>
  <div class="headerVue">
    <div class="navigation-wrap bg-light start-header start-style">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <!-- LOGO -->
          <div>
            <router-link class="logo col" to="/">
              <img src="https://i.imgur.com/cykExNN.png" alt="logo" />
            </router-link>
          </div>
          <!-- TOGGLE -->
          <button 
            @click="toggleHideSearch()"
            class="navbar-toggler col"
            type="button"
            data-toggle="collapse"
            data-target="#my-navbar"
            aria-controls="my-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- LINKS -->
          <div class="collapse navbar-collapse" id="my-navbar">
            <ul class="row navbar-nav m-0 py-2 py-md-1">
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/">الرئيسية</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/category/1">قبائل يافع وأنسابها</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/category/2">بلدان يافع</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/category/3">أعلام يافع</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/category/4">تاريخ يافع</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/videos">المكتبة المرئية</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/category/audios">الصوتيات</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/category/photos">الصور</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/library">الكتب والوثائق</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/yafiea_magazine">مجلة يافع الثقافية</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2 dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  التراث اليافعي
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <router-link class="dropdown-item" to="/category/5">أزياء</router-link>
                  <router-link class="dropdown-item" to="/category/6">عادات وتقاليد</router-link>
                  <router-link class="dropdown-item" to="/category/7">معتقدات</router-link>
                  <router-link class="dropdown-item" to="/category/8">البيت اليافعي</router-link>
                  <router-link class="dropdown-item" to="/category/9">الفنون اليافعية</router-link>
                </div>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2 dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  الأدب اليافعي
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <router-link class="dropdown-item" to="/category/10">شعر فصيح</router-link>
                  <router-link class="dropdown-item" to="/category/11">شعر عامي</router-link>
                  <router-link class="dropdown-item" to="/category/12">أمثال</router-link>
                  <router-link class="dropdown-item" to="/category/13">قصص شعبية</router-link>
                  <router-link class="dropdown-item" to="/category/14">أقوال</router-link>
                </div>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2 dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  الحياة الطبيعية
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <router-link class="dropdown-item" to="/category/15">النباتات</router-link>
                  <router-link class="dropdown-item" to="/category/16">الحيوانات</router-link>
                  <router-link class="dropdown-item" to="/category/17">الجيولوجيا</router-link>
                </div>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2 dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  الأدب
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <router-link class="dropdown-item" to="/category/18">شعر فصيح</router-link>
                  <router-link class="dropdown-item" to="/category/19">شعر عامي</router-link>
                  <router-link class="dropdown-item" to="/category/20">أمثال</router-link>
                  <router-link class="dropdown-item" to="/category/21">قصص شعبية</router-link>
                  <router-link class="dropdown-item" to="/category/22">أقوال</router-link>
                </div>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/agriculture">الزراعة في يافع</router-link>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-2">
                <router-link class="nav-link dropdown-toggle" to="/personal_pages">صحفحات شخصية</router-link>
              </li>
            </ul>
          </div>
          <!-- SEARCH -->
          <div>
            <i
              v-if="showSearchIcon"
              @click="toggleSearch()"
              class="fa-solid fa-magnifying-glass fa-beat-fade fa-1x mr-1 ml-2 search-icon"
            ></i>
          </div>
        </div>
      </nav>
    </div>
    <div v-if="showsearchbar" class="search-bar">
      <div class="container">
        <div class="row mt-3">
          <i
            @click="toggleSearch()"
            class="fa-solid fa-circle-xmark x-search close-search"
          ></i>
          <input
            class="input-search col-10 mr-2"
            type="search"
            placeholder="عن ماذا تريد ان تبحث ..."
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";

export default {
  name: "headerView",

  data() {
    return {
      showsearchbar: false,
      showSearchIcon :true,
      currentYear: null,
    };
  },

  mounted() {
    this.getYear();
    this.initialize();
  },
  beforeUnmount() {
    this.cleanup();
  },
  watch: {
    '$route'(to, from) {
      // This will be called when the route changes
      if (to.name === 'category' && from.name === 'category'&&!to.path.toString().endsWith("#")) {
        // If we're navigating between category pages, do something
        this.$router.go("/category/"+to.params.id);
      }
    }
  },
  methods: {
    toggleSearch() {
      this.showsearchbar = !this.showsearchbar;
    },
    toggleHideSearch() {
      this.showSearchIcon = !this.showSearchIcon;
    },
    getYear() {
      const currentDate = new Date();
      this.currentYear = currentDate.getFullYear();
    },
    initialize() {
      $("body").on("mouseenter mouseleave", ".nav-item", (e) => {
        if ($(window).width() > 750) {
          const _d = $(e.target).closest(".nav-item");
          _d.addClass("show");
          setTimeout(() => {
            _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
          }, 1);
        }
      });
    },
    cleanup() {
      // Clean up any event listeners or other resources if needed
      $("body").off("mouseenter mouseleave", ".nav-item");
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 992px)
{
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
}}
/* media */
router-link-active {
  color: #fff000;
}
.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 6px 0;
  box-shadow: 0 3px 3px 0 #f15a2735;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-style {
  display: block;
  position: fixed;
  top: 0;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  display: none;
}
.start-header.scroll-on .navbar-brand img {
  height: 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navigation-wrap {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar {
  padding: 0;
}
.navbar-brand img {
  height: 28px;
  width: auto;
  display: block;
  filter: brightness(10%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 3px solid var(--scond-color);
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--scond-color);
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.nav-link {
  color: var(--main-color) !important;
  font-weight: 500;

  transition: all 200ms linear;
}
@media (max-width: 767px) { 
  .nav-link {
    font-weight: 700;
  font-size: 16px;
  
  padding: 5px 15px !important;
  }
}

.nav-item:hover .nav-link {
  color: var(--scond-color) !important;
}

.nav-item.active .nav-link {
  color: var(--scond-color) !important;
}

.nav-link {
  position: relative;
  /* padding: 1px 1px !important; */
  display: inline-block;

}

.nav-item:after {
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: var(--scond-color);
  opacity: 0;
  transition: all 200ms linear;
}
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}
.nav-item.active:hover:after {
  opacity: 0;
}

.nav-item {
  position: relative;
  transition: all 200ms linear;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
  right: 0;
}

.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}

.dropdown-menu {
  padding: 5px !important;
  margin: 0;
  font-size: 16px;
  letter-spacing: 1px;
  color: var(--main-color);
  background-color: var(--section-background);
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 #f15a2751;
  transition: all 200ms linear;
}
/* hidden the dropdown Toggle */
.dropdown-toggle::after {
  color: var(--scond-color);
  font-size: 20px;
  visibility: hidden;
}


.dropdown-item {
  padding: 3px 8px;
  color: var(--main-color);
  border-radius: 2px;
  transition: all 200ms linear;
  text-align: right;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: #d6572dbd;
}

/* S logo */
.logo img {
  width: 200px;
  margin: 10px 25px 10px 20px;
}
@media (max-width: 767px) { 
  .logo img {
    width: 150px;
  }
}
.search-icon{
  font-size: 15px;
  margin: 0 0 0 100px !important;
}
@media (max-width: 1300px) {
  .logo img {
  margin: 10px 0px 10px 5px;
   
  }

  .search-icon {
    margin: 0 0 0 12px !important;
   
  }
}
@media (max-width: 768px) {
  .logo img {
  margin: 10px 10px 10px 30px;
   
  }
  .search-icon {
    margin: 0 0 0 30px !important;
   
  }
}
.logo img:hover {
  scale: 1.1;
}
/* start search */

.search-bar {
  position: absolute;
  align-items: center;
  background-color: var(--section-background);
  width: 100%;
  height: 92.84px;
  z-index: 99;
  top: 0;
  z-index: 1001;
  opacity: 1;
}
.input-search {
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  padding-right: 20px;
  font-size: 14px;
  color: var(--scond-color);

  border-style: solid;
}
.input-search:hover,
.input-search:focus {
  color: var(--main-color);
}
.x-search {
  color: var(--scond-color);

  font-size: 40px;
}
.x-search:hover {
  color: var(--main-color);
}
</style>
