<template>
    <div class="singlePost">
      <section class="single-post" :style="{backgroundImage:`url(${props.imgUrl})`}">
        <div class="landing mb-2">
        </div>
      </section>
    </div>
</template>
<script setup>
const props= defineProps({
  imgUrl:{
    required:false,
    default:'https://i.ytimg.com/vi/BOfQ6R9PZ6U/maxresdefault.jpg'
  }
})
</script>
<style scoped>
  .single-post{
    padding: 0;
    background-repeat: no-repeat; /* Prevent image repetition */
    background-position: center center; /* Center the image */
    background-size: cover; 
  }
  .single-post    .landing {
    /* background-image: url(${props.imgUrl}); */
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    background-size: cover;
    padding: 10%;
    height: 35vh;
  }
  .img-single-post{
    position: relative;
  }
  /* start  breadcrumb */
  .breadcrumb {
   text-align: center;
    background-color: #ffffff6f;
  }
  .breadcrumb-item a {
    color: var(--main-color) !important;
    font-size: 16px;
    font-weight: 700;
  }
  .breadcrumb-item a:hover {
    color: var(--scond-color) !important;
    text-decoration: none;
    cursor: pointer;
  }
  .breadcrumb-item + .breadcrumb-item::before,.breadcrumb-item.active {
    color: var(--main-color);
    font-size: 16px;
    font-weight:700;
  }

  </style>
