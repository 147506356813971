<template>
  <div class="footerVue">
    <goup />

    <footer class="bg-footer ">
      <div class="footer_section container-fluid">
        <div class="container">
          <div class="row">
            <div class="logo1 col-lg-4 col-sm-6">
              <img
                class=""
                src="../assets/imgs/logo.png"
                style="width: 350px; padding: 35px 0 5px; margin: 0"
                alt=""
              />
              <p>
                الموسوعة اليافعية هو اكبر عمل موسوعي معرفي للتعريف بيافع ويعتبر
                أول وأضخم عمل موسوعي للتعريف بقبيلة مِن أعرق القبائل و الاعلام
                والمشاهير والجغرافيا والتاريخ والتراث والثقافة والطبيعة والبيئة.
              </p>
            </div>

            <!-- <div class="logo1 col-lg-4 col-sm-6">
              <h4 class="mt-4">الروابط السريعة</h4>
            </div> -->
            <div class="logo1 col-lg-4 col-sm-6">
            
              <h4 class="mt-2">التواصل</h4>

                <ul class="socials mt-2">
                  <li> 
                  <a href="https://x.com/yafe3pedia" 
                  class="fab fa-x-twitter"
                  title="تابعنا على منصة اكس" 
                  target="_blank"></a> </li>

                  <li> 
                    <a href="https://www.facebook.com/yafeapedia/" 
                    class="fa-brands fa-facebook-f fa-fw" title="تابعنا على فيس بوك" target="_blank"></a>
                  </li>


                    <li>
                      <a href="https://www.youtube.com/channel/UCl5Zkl4hEr8Rt33uQ16HPAw"
                      class="fab fa-youtube" title="تابعنا على اليوتيوب" target="_blank"></a>
                      </li>
                     
                        <li> 
                          <a href="https://t.me/yafeapedia" 
                          class="fab fa-tiktok" 
                          title="تابعنا على تك توك" target="_blank"></a> </li>

                          <li> <a href="https://www.instagram.com/yafeapedia/" 
                        class="fab fa-instagram" title="تابعنا على Instagram"
                        target="_blank"></a> </li>
                        
                        <li> <a href="https://t.me/yafeapedia" 
                          class="fab fa-telegram" 
                          title="تابعنا على  تلجرام" target="_blank"></a> </li>
                </ul>
             
            </div>
        </div>
        </div>
        <div class="container rights mb-2">
          <p>
            كل الحقوق محفوظة
            <a style="color: var(--scond-color)" href="/"
              ><span style="font-weight: bolder; color: var(--scond-color)"
                >للموسوعة اليافعية</span></a>
                للثقافة والإعلام
            &copy; <span id="displayYear">{{ currentYear }}</span>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>

import goup from "@/components/goUpVue.vue";
export default {
  name: "footerview",
  components: {
    goup,
  },
  data() {
    return {
      currentYear: null,
    };
  },
  mounted() {
    this.getYear();
  
  },
  beforeUnmount() {
    this.cleanup();
  },
  methods: {
    getYear() {
      const currentDate = new Date();
      this.currentYear = currentDate.getFullYear();
    },
   
    cleanup() {
      // Clean up any event listeners or other resources if needed
      $("body").off("mouseenter mouseleave", ".nav-item");
    },
  },
};
</script>

<style scoped>

.socials {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.socials li {
  margin: 0 10px;
}

.socials a {
  display: block;
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--text-color);
  color: var(--main-color);
}
.socials a:hover {

background-color: var(--scond-color);
  color: var(--text-color);
  transform: scale(1.5);
  text-decoration: none;
  
}
.footer_section {
  padding: 15px;
  /* background-color: var(--scond-color); */
  /*background-image: url("../assets/imgs/p.png");*/
  background-position: center;
  background-repeat: repeat;
  background-size: 25%;
  overflow: hidden;
  padding-bottom: 20px;
}

.footer p {
  padding: 0;
}
.footer_section .rights {
  position: relative;
  /* background-color: var(--section-background); */
  text-align: center;
}

footer p,
h4 {
  color: var(--text-color);
  padding: 10px 0;
  margin: 0;
  /* font-size: .9rem; */
  /* line-height: 1.6; */
}
.bg-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    var(--main-color) 0%,
    var(--main-color) 25%,
    var(--main-color) 51%,
    var(--scond-color) 100%
  );
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*end  footer */
</style>
