<template>  
  <div style="background-color: var(--section-background);
     box-shadow: 0 3px 10px rgba(117, 117, 117, 0.599);" class="d-flex flex-wrap justify-content-around">
     <!-- class="names-container"> -->
 <div class="col-12 col-md-4 text-center py-4">
        <label class="h4 text-primary">
          محمد سالم بن علي جابر
        </label>
        <br>
        <br>
        <label class="h5 text-primary">
          المشرف العام
        </label>
      </div>

      <div class="col-12 col-md-4 text-center py-4">
        <label class="h4 text-primary">
          د. نادر سعد العمري
        </label>
        <br>
        <br>
        <label class="h5 text-primary">
          المحرر العلمي
        </label>
      </div>

      <div class="col-12 col-md-4 text-center py-4">
        <label class="h4 text-primary">
          قاسم عمر المشتهر
        </label>
        <br>
        <br>
        <label class="h5 text-primary">
          المدير العام
        </label>
      </div>
      </div>
</template>

<style scoped>
.text-primary{
  color: var(--main-color) !important;
  font-family: 'Cairo', 'Roboto', sans-serif !important;
}
</style>
