<template>
  <!-- <div class="sk-ww-youtube-channel-videos" :data-embed-id="embedId"></div> -->
  <iframe src='https://widgets.sociablekit.com/youtube-playlists/iframe/25354313' frameborder='0' width='100%' height='1000'></iframe>

</template>

<!-- 
<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'YoutubeChannelVideos', 
  setup() {
    const embedId = ref('25350622');

    onMounted(() => {
      const script = document.createElement('script');
      script.src = 'https://widgets.sociablekit.com/youtube-channel-videos/widget.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    });

    return {
      embedId,
    };
  },
};
</script> -->