<template>

  <div class="swiper-container">
    <swiper
    :pagination="{
      dynamicBullets : true,
      clickable: true,
    }"
    :autoplay="{
      delay: 3000,
      autoplayDisableOnInteraction: false
          }"
      :modules="modules"
      :slides-per-view="swiperOptions.slidesPerView"
       :space-between="swiperOptions.spaceBetween" 
     
      :loop="true"
      :updateOnWindowResize="true"
    :renderExternalUpdate="true"
    :observer="true"
    :observeParents="true"
    :observeSlideChildren="true"
    :speed="3000"
    :height="600"
    :lazy="true"
    :effect="fade"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="image in images" :key="image.id">
        <div class="item">
              <img class="header-image" :src="image.url" alt="banner" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
  </template>
<script setup>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { getDatabase, ref as dbRef, onValue} from 'firebase/database';

const imagesRef = dbRef(getDatabase(), 'home/images/');
const images=ref([]);
// const props = defineProps({
//   posts: Array,
// });

const swiperOptions = ref({
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    clickable: true,
  },
  scrollbar: {
    draggable: true,
  },
});

const updateSlidesPerView = () => {
  swiperOptions.value.slidesPerView = 1;
};

onMounted(() => {
  onValue(imagesRef, (snapshot) => {
    images.value = [];
    snapshot.forEach((childSnapshot) => {
      images.value.push({ id: childSnapshot.key, ...childSnapshot.val() });
    });
  }); 
  updateSlidesPerView();
  window.addEventListener("resize", updateSlidesPerView);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSlidesPerView);
});

const onSwiper = (swiper) => {
  // console.log(swiper);
};

const onSlideChange = () => {
  // console.log("slide change");
};

const modules = computed(() => {
  return [Navigation, Pagination, Scrollbar, A11y, Autoplay];
});

</script>

  <style scoped>
  .header-image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 30vh;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
  </style>