<script setup>
import {ref} from "vue"
import breadcrumb from '@/components/breadcrumb.vue'
import { useRoute } from 'vue-router';
import {ApiClient, ArticleControllerApi} from "@/api/index.js"
import {formatDate} from "@/utils/utils.js"
import QuillView from "./QuillView.vue";

const route =useRoute()
const article=ref(null);

const apiClient=new ApiClient();
const articleApi=new ArticleControllerApi(apiClient);


function loadArticle(){
  articleApi.getArticle(route.params.id,(error,data,response)=>{
    // data.content=data.content;
    article.value=data;
  });
}
loadArticle();
</script>
<template>
  <div class="singlePost">
    
    <section class="singlePostVue1">

   <breadcrumb :imgUrl="article?.imgUrl"/>
      
      <div class="container mb-3 singlePostVue">
        <div class="row">
          <!-- العنوان -->
          <div class="col-12 mt-3 txt-center">
            <h2>{{ article?.title }}</h2>
          </div>
          <!-- تفاصيل المنشور -->
          <div class="col-12 text-center mt-3">
            <div class="">
              <span
                ><i
                  class="fa-regular fa-circle-user txt-center"
                  style="color: #d06106; margin: 0 3px"
                ></i
              ></span>
              <span class="post-aother">{{ article?.author.displayName}}</span>
            </div>
            <div class="">
              <span>
                <i
                  class="fa-regular fa-clock"
                  style="color: #d06106; margin: 0 3px"
                ></i>
              </span>
              <span class="post-date">{{ formatDate(article?.addedAt)}}</span>
            </div>
          </div>
          <!-- صورة المنشور -->

          <!-- <div class="col-12 text-center">
            <img
            :src="article?.imgUrl"
              class="img-thumbnail mt-3 mb-3"
              style="width: 500px"
              alt=""
            />
          </div> -->
          <div class="col-12" style="position: relative;">
            <!-- <div style=" position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(255, 255, 255);
            opacity: 0;
            z-index: 1;"> -->
            <!-- </div>             -->
            <QuillView :content="article?.content"/>
            <!-- <p class="quill-editor" style="color: #d06106;" v-html="article?.content"/> -->
          </div>
        </div>
      </div>
    </section>
  </div>

</template>
<script>
export default {
  name: "singlePost",
  props: {},
};
</script>
<style scoped>
.singlePostVue1 {
  padding: 0;
}
.singlePostVue1 .landing {
  background-image: url("");
  background-repeat: no-repeat;
  position: relative;
  background-position: center center;
  background-size: cover;
  padding: 10%;
  height: 35vh;
}

.img-singlePostVue1 {
  position: relative;
}
/* start  breadcrumb */
.breadcrumb {
  text-align: center;
  background-color: #ffffff6f;
}
/* iframe.ql-video {
   
} */

.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item.active {
  color: var(--main-color);
}

.breadcrumb-item:hover {
  color: var(--scond-color);
}
/* end  breadcrumb */
.singlePostVue {
  background-color: rgba(241, 239, 239, 0.767);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-bottom: 15px;
}
.singlePostVue h5,.singlePostVue P {
  margin: 10px 0;
  font-size: 17px;
  padding: 10px 50px;
}
@media (max-width: 767px) { 
  .singlePostVue h5, .singlePostVue p {
    padding: 0;  /* Remove padding on mobile */
    text-align: center;
  }
}
/* end  singlePostVue */
</style>
