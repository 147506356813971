<script setup>
import ParentSeries from '@/components/ParentSeries.vue'
import Breadcrumb from '@/components/breadcrumb.vue'
import { ref, onMounted } from 'vue'
import {ApiClient,AlbumDTO,MediaControllerApi} from '@/api/index.js'
import { useRoute } from 'vue-router';


const albums = ref([])
const apiClient=new ApiClient();
const mediaApi=new MediaControllerApi(apiClient);

const route =useRoute()

const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);


onMounted(async () => {
  // Fetch albums from API and convert to AlbumDTO
  // This is a placeholder, replace with actual API call
  
  mediaApi.getMediaByCategory(route.params.id,route.params.id=='photos'?0:1,1,
  {page:currentPage.value,size:pageSize.value},(error,data,response)=>{
    albums.value=data.content;
    // albums.value = data.map(album => AlbumDTO.constructFromObject(album))
  });
  // const response = await fetch('/api/albums')
  // const data = await response.json()
  
})
</script>

<template>
  <div class="Media">
    <Breadcrumb />
    <div class="parentSeries">
      <div class="container">
        <div class="row">
          <ParentSeries
            v-for="album in albums"
            :key="album.id"
            :album="album"
            class="col-sm-6 col-lg-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

