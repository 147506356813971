import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/main.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB4qE6mHCJ25IjZUhdeapf3Z45-a6jtWY4",
  authDomain: "hazemdev.firebaseapp.com",
  databaseURL: "https://hazemdev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hazemdev",
  storageBucket: "hazemdev.appspot.com",
  messagingSenderId: "941595306107",
  appId: "1:941595306107:web:9e25868b8a8e3c6c7fdade"
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const app = createApp(App)
app.use(router)
app.mount('#app')


