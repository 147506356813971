const formatDate = (dateString) => {
    if(!dateString)
        return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(navigator.language, {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(date);
  };
  
  export {formatDate};